import * as React from 'react';
import { WatermarkMediaDefault } from '../assets';
/**
 * Default layout for a Watermark card including an href, thumbnail, and children.
 */
export class GalleryHitLayout extends React.Component {
    static defaultProps = {
        className: 'ais-Hits-item',
        aspectRatio: '16by9',
        thumbPosition: 'top',
    };
    /**
     * Sets the URL path to the default thumbnail image.  Can be overridden.
     */
    static DefaultThumbnail = WatermarkMediaDefault;
    render() {
        const { href, title, className, thumbnailUrl, thumbPosition, aspectRatio, thumbnailOverlay, children, } = this.props;
        const cols = this.props.columnClasses || ['col-10 col-md-4'];
        const thumb = React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("div", { className: `${className}-thumb embed-responsive embed-responsive-${aspectRatio}` },
                React.createElement("img", { className: `embed-responsive-item card-img-${(thumbPosition != 'bottom') ? 'top' : 'bottom'}`, src: thumbnailUrl || GalleryHitLayout.DefaultThumbnail }),
                thumbnailOverlay));
        return React.createElement("div", { className: `${cols.join(' ')} widget-media-gallery__hit` },
            React.createElement("a", { href: href, className: `${className} a-block d-block card` },
                thumbPosition != 'bottom' && thumb,
                React.createElement("div", { className: "card-body" },
                    title && React.createElement("h2", { className: "card-title" }, title),
                    children),
                thumbPosition == 'bottom' && thumb));
    }
}
