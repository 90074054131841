/*
 * Parse a date string into a Date assuming that it is in local time (not UTC)
 * https://stackoverflow.com/a/42626876/2192243
 */
export function parseDateISOString(str) {
    const ds = str.split(/\D/).map((s) => parseInt(s, 10));
    ds[1] = ds[1] - 1; // adjust month
    // Date constructor checks the actual number of params so we can't just pass
    // undefined in indexes 2-6
    if (ds.length > 6) {
        return new Date(ds[0], ds[1], ds[2], ds[3], ds[4], ds[5], ds[6]);
    }
    if (ds.length > 5) {
        return new Date(ds[0], ds[1], ds[2], ds[3], ds[4], ds[5]);
    }
    if (ds.length > 4) {
        return new Date(ds[0], ds[1], ds[2], ds[3], ds[4]);
    }
    if (ds.length > 3) {
        return new Date(ds[0], ds[1], ds[2], ds[3]);
    }
    if (ds.length > 2) {
        return new Date(ds[0], ds[1], ds[2]);
    }
    return new Date(ds[0], ds[1]);
}
export async function wait(ms = 0) {
    return new Promise((resolve) => setTimeout(() => resolve(), ms));
}
export async function waitUntil(fn, timeout, step) {
    const start = Date.now();
    while (!(fn())) {
        await wait(step || 10);
        if (Date.now() - start > (timeout || 1000)) {
            throw new Error(`Timeout while waiting! waited ${Date.now() - start}ms`);
        }
    }
}
export function isEmpty(obj) {
    if (!obj) {
        return true;
    }
    for (const key of Object.keys(obj)) {
        const val = obj[key];
        if (val) {
            if (typeof (val) == 'string') {
                if (val.length > 0) {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    }
    return true;
}
export function present(value) {
    if (typeof value == 'string') {
        return value.length > 0;
    }
    return !!value;
}
export function assert(value, key, msg) {
    if (!present(value)) {
        throw new Error(msg || 'assert failed! Value was not present');
    }
}
