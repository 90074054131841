/**
 * Wraps async functions to handle thrown errors and forward them to the given
 * ErrorHandlingComponent
 */
export class SimpleErrorHandler {
    target;
    constructor(
    /** The Target whose state should be set on errors */
    target) {
        this.target = target;
    }
    /**
     * Returns an error handling wrapper function which forwards any errors to the target
     * component
     * The returned wrapper function will log any async errors from the source component
     * and then set the state on the target component.
     * @param fn The function that should be wrapped
     * @returns A wrapped version of that function
     */
    wrap(fn) {
        /** The error handler */
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        /** A function which wraps the async function, providing error handling */
        return (async function (...args) {
            /** The component which owns the function */
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const source = this;
            let evt = null;
            if (args[0] && typeof args[0] == 'object' &&
                'preventDefault' in args[0] &&
                typeof args[0].preventDefault == 'function') {
                evt = args[0];
            }
            if (evt) {
                evt.preventDefault();
                if ('persist' in evt) {
                    // https://reactjs.org/docs/events.html#event-pooling
                    evt.persist();
                }
            }
            setState({ error: null, wait: true });
            try {
                const result = await fn.apply(source, args);
                setState({ error: null, wait: false });
                return result;
            }
            catch (exception) {
                // for some reason the shopify client throws an array of errors.
                const errors = Array.isArray(exception) ? exception : [exception];
                let ex;
                for (ex of errors) {
                    console.error(ex);
                }
                ex = errors[0];
                if (typeof ex == 'string') {
                    ex = new Error(ex);
                }
                setState({ error: ex, wait: false });
            }
            function setState(state) {
                if (self.target) {
                    self.target.setState(state);
                }
                if (source !== self.target && 'setState' in source) {
                    source.setState(state);
                }
            }
        });
    }
}
