import * as React from 'react'
import { connectHits, Hit } from 'react-instantsearch-core'

import { ArchiveIndexView } from './archive-index-view'
import { IMessageDoc } from '../../models/algolia/message'

interface IProps {
  hits: Array<Hit<IMessageDoc>>,
}

function ArchiveResults(props: IProps) {
  const { hits } = props

  return <ArchiveIndexView
    attribute='date_year'
    hits={hits}
    defaultRefinement={yearRefinement()}
    limit={100}
  />
}


const ArchiveSearchResults = connectHits<IProps, IMessageDoc>(ArchiveResults)

export { ArchiveSearchResults }

function yearRefinement(): string {
  if(window.location.search) {
    const params = new URLSearchParams(window.location.search)
    const year = params.get('menu[date_year]')
    if(year) {
      return year
    }
  }

  return ''
}
