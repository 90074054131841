import { createConnector } from 'react-instantsearch-core';
// Do this as a connector so as to avoid these showing up in all the refinement
// list components
const connectHiddenRefinements = createConnector({
    displayName: 'TabHiddenRefinements',
    getProvidedProps() {
        return {};
    },
    getSearchParameters(searchParameters, props, _state) {
        const { hiddenFacets, operator } = props;
        if (!hiddenFacets) {
            return searchParameters;
        }
        // If the operator is 'and', then we need to use 'addFacet' & 'addFacetRefinement'
        // else, the operator is 'or' which means a disjunctive facet search.
        const addKey = operator === 'and' ? 'addFacet' : 'addDisjunctiveFacet';
        const addRefinementKey = operator === 'and' ? 'addFacetRefinement' : 'addDisjunctiveFacetRefinement';
        for (const attribute of Object.keys(hiddenFacets)) {
            const value = hiddenFacets[attribute];
            searchParameters = searchParameters[addKey](attribute);
            if (Array.isArray(value)) {
                value.forEach((v) => searchParameters = searchParameters[addRefinementKey](attribute, v.toString()));
            }
            else {
                searchParameters = searchParameters[addRefinementKey](attribute, value.toString());
            }
        }
        return searchParameters;
    },
});
const HiddenFacets = connectHiddenRefinements(() => null);
export default HiddenFacets;
export { HiddenFacets, };
