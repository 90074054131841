import * as React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-core';
import { Configure } from 'react-instantsearch-dom';
import AlgoliaSearch from '../algolia-search';
import HiddenFacets from '../algolia-search/hidden-facets';
import { MediaLoader } from '../media-loader';
import GalleryBlockHit from './gallery-block-hit';
import { ConnectedAlgoliaViewAllLink } from './view-all-link';
/**
 * React component that asynchronously loads media from a configurable source
 * and displays it in react components.
 */
class MediaGallery extends React.Component {
    static defaultProps = {
        widgetName: 'media-gallery',
        campusAware: false,
        mediaSourceDefaults: {},
        HitComponent: GalleryBlockHit,
        LoadingComponent: (props) => React.createElement(GalleryBlockHit, { ...props, loading: true }),
    };
    render() {
        const { header, viewAllLink, viewAllLinkText, widgetName, apiKey, appId, HitComponent, LoadingComponent } = this.props;
        const mediaSource = {
            // defaults for eg. algolia index,
            ...this.props.mediaSourceDefaults,
            // override with the campus-specific settings
            ...this.props.mediaSource,
        };
        const exclude = typeof (this.props.excludeIDs) == 'string' ?
            [this.props.excludeIDs] :
            this.props.excludeIDs || [];
        const columns = typeof this.props.columns == 'number' ?
            [`col-${this.props.columns}`] :
            this.props.columns && parseColumns(this.props.columns);
        switch (mediaSource.source) {
            case 'Algolia':
                return (React.createElement(AlgoliaSearch, { indexName: mediaSource.index, apiKey: apiKey, appId: appId, hosts: mediaSource.hosts, widgetName: widgetName || 'media-gallery' },
                    React.createElement(Configure, { analytics: false }),
                    mediaSource.hiddenFacets &&
                        React.createElement(HiddenFacets, { hiddenFacets: mediaSource.hiddenFacets }),
                    mediaSource.facetFilters &&
                        React.createElement(HiddenFacets, { hiddenFacets: mediaSource.facetFilters, operator: mediaSource.operator }),
                    React.createElement(Configure, { hitsPerPage: mediaSource.hitsPerPage || 6 }),
                    React.createElement("div", { className: "widget-media-gallery__header" },
                        header !== false &&
                            typeof header == 'string' ? React.createElement("h4", null, header) : header,
                        viewAllLink &&
                            React.createElement(ConnectedAlgoliaViewAllLink, { href: viewAllLink, viewAllLinkText: viewAllLinkText, limit: mediaSource.hitsPerPage || 3 })),
                    React.createElement(AlgoliaLoader, { hitsPerPage: mediaSource.hitsPerPage || 6, LoadingComponent: LoadingComponent, columnClasses: columns },
                        React.createElement(AlgoliaGalleryBlockLayout, null, (hit) => React.createElement(HitComponent, { hit: hit, columnClasses: columns, key: `media-gallery-message-${hit.objectID}` })))));
            case 'Playlist':
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "widget-media-gallery__header" },
                        header !== false &&
                            typeof header == 'string' ? React.createElement("h4", null, header) : header,
                        viewAllLink &&
                            React.createElement("a", { href: viewAllLink, className: "btn btn-link" }, "View All")),
                    React.createElement(MediaLoader, { baseUrl: mediaSource.apiUrl, endpoint: "playlists", id: mediaSource.playlistId, columnClasses: columns, LoadingComponent: () => {
                            if (LoadingComponent) {
                                return React.createElement("div", { className: "row hit-list" }, new Array(mediaSource.hitsPerPage || 6).fill(0).map((_, i) => {
                                    return React.createElement(LoadingComponent, { columnClasses: columns, key: `media-gallery-loader-${i}` });
                                }));
                            }
                            else {
                                return React.createElement("div", { className: "loading-bar" });
                            }
                        }, HitComponent: ({ playlist }) => {
                            return React.createElement("div", { className: "row hit-list" }, playlist.messages
                                .filter((h) => exclude.indexOf(h.id.toString()) == -1)
                                .map((hit) => {
                                return React.createElement(HitComponent, { hit: hit, columnClasses: columns, key: `media-gallery-message-${hit.id}` });
                            }));
                        } })));
            default:
                unrecognizedMediaSource(mediaSource);
        }
    }
}
const AlgoliaGalleryBlockLayout = connectHits(({ hits, exclude, children, }) => {
    if (exclude && exclude.length > 0) {
        hits = hits.filter((h) => exclude.indexOf(h.objectID.toString()) == -1);
    }
    return React.createElement("div", { className: "row hit-list" }, hits.map(children));
});
const AlgoliaLoader = connectStateResults(({ hitsPerPage, searchResults, LoadingComponent, children, ...other }) => {
    if (!searchResults) {
        if (!LoadingComponent) {
            return React.createElement("div", { className: "loading-bar" });
        }
        else {
            return React.createElement("div", { className: "row hit-list" }, new Array(hitsPerPage || 6).fill(0).map((_, i) => {
                return React.createElement(LoadingComponent, { ...other, key: `media-gallery-loader-${i}` });
            }));
        }
    }
    return children;
});
function unrecognizedMediaSource(source) {
    throw new Error(`Unrecognized media source: ${source.source}`);
}
function parseColumns(colspec) {
    const cols = [];
    Object.keys(colspec).forEach((key) => {
        if (key == 'xs') {
            cols.push(`col-${Math.round(12 / colspec.xs)}`);
        }
        else {
            cols.push(`col-${key}-${Math.round(12 / colspec[key])}`);
        }
    });
    return cols;
}
export { GalleryBlockHit, MediaGallery, };
