import React from 'react';
import template from 'lodash-es/template';
import { connectStats } from 'react-instantsearch-core';
const AlgoliaViewAllLink = (props) => {
    const { nbHits, href, viewAllLinkText, limit } = props;
    if (!viewAllLinkText) {
        return (React.createElement("a", { href: href, className: "btn btn-link" }, "View All"));
    }
    let text = template(viewAllLinkText.default);
    if (nbHits > limit) {
        text = template(viewAllLinkText.many);
    }
    return (React.createElement("a", { href: href, className: 'btn btn-link' }, text({ count: nbHits }) || 'View All'));
};
export const ConnectedAlgoliaViewAllLink = connectStats(AlgoliaViewAllLink);
