import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';
import React from 'react';
import ReactAutosuggest from 'react-autosuggest';
import { connectStateResults, HIGHLIGHT_TAGS, } from 'react-instantsearch-core';
import { Highlight } from 'react-instantsearch-dom';
const defaultState = {
    ...HIGHLIGHT_TAGS,
    hitsPerPage: 10,
    page: 0,
    ruleContexts: ['autocomplete'],
    // TODO: https://github.com/watermarkchurch/paper-signs/issues/2465
    analytics: false,
    clickAnalytics: false,
    // Autocomplete doesn't need to show multiple "re:generation" rows.
    distinct: true,
};
class Autocomplete extends React.Component {
    helper;
    constructor(props, context) {
        super(props, context);
        const client = ('search' in props.autosuggestClient) ?
            props.autosuggestClient :
            algoliasearch(props.autosuggestClient.appId, props.autosuggestClient.apiKey, {
                logger: console
            });
        this.helper = algoliasearchHelper(client, this.index(), defaultState);
        this.state = {
            hits: [],
        };
    }
    index = () => {
        if (this.props.autosuggestIndex) {
            return this.props.autosuggestIndex;
        }
        if (this.props.searchResults) {
            return this.props.searchResults.index;
        }
        return this.props.defaultIndex;
    };
    // eslint-disable-next-line react/no-deprecated
    componentWillMount = () => {
        // need to set the facets/searchableAttributes on initial load as well
        this.componentDidUpdate();
    };
    /**
     * Since Autocomplete performs an independent search with the same filters as
     * the main search, we need to take the main search state (given in props)
     * and override certain query fields like searchable attributes
     * and unique, for the purposes of autocomplete results.
     */
    componentDidUpdate = () => {
        const index = this.index();
        if (!this.props.searchResults) {
            this.helper.setState(defaultState);
            return;
        }
        const state = this.props.searchResults._state;
        const newState = Object.assign({}, state, defaultState, {
            // we set our index here
            index,
            // TODO: https://github.com/watermarkchurch/paper-signs/issues/2465
            analytics: false,
            clickAnalytics: false
        });
        // Update our state
        this.helper.setState(newState);
    };
    componentDidMount = () => {
        this.helper.on('result', this.handleSearchSuccess);
    };
    componentWillUnmount = () => {
        this.helper.removeListener('result', this.handleSearchSuccess);
    };
    render() {
        const { inputProps } = this.props;
        const { hits } = this.state;
        // work around a bug in ReactAutosuggest
        inputProps.value = inputProps.value || '';
        return React.createElement(NoMouseoverAutosuggest, { 
            // alwaysRenderSuggestions={true}
            suggestions: hits, multiSection: false, onSuggestionsFetchRequested: this.onSuggestionsFetchRequested, onSuggestionsClearRequested: this.onSuggestionsClearRequested, onSuggestionSelected: this.onSuggestionSelected, getSuggestionValue: this.getSuggestionValue, renderSuggestion: this.renderSuggestion, inputProps: inputProps });
    }
    handleSearchSuccess = ({ results }) => {
        if (results.index == this.index()) {
            this.setState({
                hits: results.hits,
            });
        }
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.helper.setQuery(value);
        this.helper.search();
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            hits: [],
        });
    };
    onSuggestionSelected = (evt, data) => {
        if (this.props.onSelected) {
            this.props.onSelected(evt, data.suggestionValue);
        }
    };
    /**
     * Gets the suggested value from a hit.  This suggested value will be put into
     * the search bar if the suggestion is accepted.
     */
    getSuggestionValue = (hit) => {
        return hit.title || '';
    };
    /**
     * Renders the suggested hit result in the autocomplete dropdown.
     */
    renderSuggestion = (hit) => {
        return React.createElement(Highlight, { attribute: "title", hit: hit });
    };
}
const ConnectedAutocomplete = connectStateResults(Autocomplete);
export default ConnectedAutocomplete;
/**
 * This gets around the default ReactAutosuggest behavior of selecting a
 * suggestion when the mouse is hovering and the user presses enter.
 */
class NoMouseoverAutosuggest extends ReactAutosuggest {
    onSuggestionMouseEnter = () => {
        // ignore
    };
}
