import $ from 'jquery';
import * as React from 'react';
import { connectSearchBox, translatable } from 'react-instantsearch-core';
import ConnectedAutocomplete from './autocomplete';
const defaultLoadingIndicator = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 38 38", xmlns: "http://www.w3.org/2000/svg", stroke: "#444" },
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("g", { transform: "translate(1 1)", strokeWidth: "2" },
            React.createElement("circle", { strokeOpacity: ".5", cx: "18", cy: "18", r: "18" }),
            React.createElement("path", { d: "M36 18c0-9.94-8.06-18-18-18" },
                React.createElement("animateTransform", { attributeName: "transform", type: "rotate", from: "0 18 18", to: "360 18 18", dur: "1s", repeatCount: "indefinite" }))))));
class SearchBox extends React.Component {
    static defaultProps = {
        currentRefinement: '',
        className: '',
        focusShortcuts: ['s', '/'],
        autoFocus: false,
        searchAsYouType: true,
        showLoadingIndicator: false,
        isSearchStalled: false,
        loadingIndicator: defaultLoadingIndicator,
        reset: React.createElement("i", { className: "material-icons" }, "clear"),
        submit: React.createElement("i", { className: "material-icons" }, "search"),
    };
    constructor(props) {
        super(props);
        this.state = {
            query: props.searchAsYouType ? null : props.currentRefinement,
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
        if (this.props.autoFocus && (!this.state.query || !this.state.query.length)) {
            const input = $('.ais-SearchBox-input');
            if (input.length) {
                setTimeout(() => input.trigger('touchstart focus').focus(), 0);
            }
        }
        this.setState({
            mounted: true,
        });
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        // Reset query when the searchParameters query has changed.
        // This is kind of an anti-pattern (props in state), but it works here
        // since we know for sure that searchParameters having changed means a
        // new search has been triggered.
        if (!nextProps.searchAsYouType &&
            nextProps.currentRefinement !== this.props.currentRefinement) {
            this.setState({
                query: nextProps.currentRefinement,
            });
        }
    }
    getQuery = () => this.props.searchAsYouType
        ? this.props.currentRefinement
        : this.state.query;
    // From https://github.com/algolia/autocomplete.js/pull/86
    onKeyDown = (e) => {
        if (!this.props.focusShortcuts) {
            return;
        }
        const shortcuts = this.props.focusShortcuts.map((key) => typeof key === 'string' ? key.toUpperCase().charCodeAt(0) : key);
        const elt = e.target || e.srcElement;
        const tagName = elt.tagName;
        if (elt.isContentEditable ||
            tagName === 'INPUT' ||
            tagName === 'SELECT' ||
            tagName === 'TEXTAREA') {
            // already in an input
            return;
        }
        const which = e.which || e.keyCode;
        if (shortcuts.indexOf(which) === -1) {
            // not the right shortcut
            return;
        }
        e.stopPropagation();
        e.preventDefault();
    };
    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { refine, searchAsYouType, onSubmit } = this.props;
        if (!searchAsYouType) {
            refine(this.getQuery());
        }
        if (onSubmit) {
            onSubmit(e);
        }
        return false;
    };
    onChange = (event) => {
        const { searchAsYouType, refine, onChange } = this.props;
        const value = event.target.value;
        if (searchAsYouType) {
            refine(value);
        }
        else {
            this.setState({ query: value });
        }
        if (onChange) {
            onChange(event);
        }
    };
    onReset = (event) => {
        const { searchAsYouType, refine, onReset } = this.props;
        refine('');
        if (!searchAsYouType) {
            this.setState({ query: '' });
        }
        if (onReset) {
            onReset(event);
        }
    };
    onAutocompleteSelected = (e, value) => {
        const { searchAsYouType, refine, onChange, onSubmit } = this.props;
        if (searchAsYouType) {
            refine(value);
        }
        else {
            this.setState({ query: value });
        }
        if (onChange) {
            onChange(e);
        }
        if (!searchAsYouType) {
            setTimeout(() => {
                refine(this.getQuery());
                if (onSubmit) {
                    onSubmit(e);
                }
            }, 50);
        }
    };
    render() {
        const { className, translate, loadingIndicator, submit, reset, } = this.props;
        const query = this.getQuery();
        const searchInputEvents = Object.keys(this.props).reduce((props, prop) => {
            if (['onsubmit', 'onreset', 'onchange'].indexOf(prop.toLowerCase()) ===
                -1 &&
                prop.indexOf('on') === 0) {
                return { ...props, [prop]: this.props[prop] };
            }
            return props;
        }, {});
        const isSearchStalled = !this.state.mounted ||
            this.props.showLoadingIndicator && this.props.isSearchStalled;
        const isCurrentQuerySubmitted = query && query == this.props.currentRefinement;
        const button = isSearchStalled ? 'loading' :
            isCurrentQuerySubmitted ? 'reset' : 'submit';
        return (React.createElement("div", { className: `ais-SearchBox ${className}` },
            React.createElement("form", { noValidate: true, onSubmit: this.onSubmit, onReset: this.onReset, className: `ais-SearchBox-${isSearchStalled ? 'form--stalledSearch' : 'form'}`, action: "", role: "search" },
                React.createElement("button", { type: "submit", title: translate('submitTitle'), className: `ais-SearchBox-submit`, hidden: button != 'submit' }, submit),
                React.createElement("button", { type: "reset", title: translate('resetTitle'), className: `ais-SearchBox-reset`, hidden: button != 'reset' }, reset),
                React.createElement("span", { className: `ais-SearchBox-loadingIndicator`, hidden: button != 'loading' }, loadingIndicator),
                React.createElement(ConnectedAutocomplete, { defaultIndex: this.props.defaultIndex, autosuggestIndex: this.props.autosuggestIndex, autosuggestClient: this.props.autosuggestClient, onSelected: this.onAutocompleteSelected, inputProps: {
                        onChange: this.onChange,
                        value: query,
                        type: 'search',
                        placeholder: translate('placeholder'),
                        autoFocus: false,
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        autoCapitalize: 'off',
                        spellCheck: false,
                        required: true,
                        maxLength: 512,
                        disabled: !this.state.mounted,
                        ...searchInputEvents,
                        className: `ais-SearchBox-input`,
                    } }))));
    }
}
const TranslatableSearchBox = translatable({
    resetTitle: 'Clear the search query.',
    submitTitle: 'Submit your search query.',
    placeholder: 'Search here…',
})(SearchBox);
const ConnectedSearchBox = connectSearchBox(TranslatableSearchBox);
export default ConnectedSearchBox;
