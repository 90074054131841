import React from 'react';
/**
 * Joins an array of JSX elements together with a separator into one Fragment.
 * We need this because string.join() converts them all into strings but we need
 * the result to be a JSX.Element
 * @returns
 */
export function joinJsx(array, separator) {
    const children = [];
    for (const elem of array) {
        if (children.length > 0) {
            children.push(separator);
        }
        children.push(elem);
    }
    return React.createElement(React.Fragment, null, ...children);
}
