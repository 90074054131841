import * as React from 'react';
import { SimpleErrorHandler } from '../errorHandler';
/**
 * Loads media from a playlist behind the scenes.
 */
export class MediaLoader extends React.Component {
    /** Change the ErrorHandler component that is used to  */
    static ErrorHandler = SimpleErrorHandler;
    errorHandler = new MediaLoader.ErrorHandler(this);
    constructor(props, context) {
        super(props, context);
        this.state = {
            wait: true,
            error: null,
        };
        this.loadMedia = this.errorHandler.wrap(this.loadMedia);
    }
    componentDidMount() {
        this.loadMedia();
    }
    render() {
        const { HitComponent, LoadingComponent, 
        /* eslint-disable @typescript-eslint/no-unused-vars */
        endpoint, id, baseUrl, fetch, onLoad, onError, 
        /* eslint-enable @typescript-eslint/no-unused-vars */
        ...other } = this.props;
        const state = this.state;
        if (state.wait == true) {
            if (LoadingComponent) {
                return React.createElement(LoadingComponent, { ...other });
            }
            else {
                return React.createElement("div", { className: "loading-bar" });
            }
        }
        else if (state.error || !state.response) {
            return React.createElement("span", { className: "error" }, "An unexpected error ocurred!");
        }
        return React.createElement(HitComponent, { ...other, ...state.response });
    }
    async loadMedia() {
        const { endpoint, id, onLoad, onError } = this.props;
        const url = resolve(this.props.baseUrl || `https://media.watermark.org/api/v1`, [endpoint, id].join('/'));
        try {
            const f = this.props.fetch || fetch;
            const resp = await f(url.toString());
            if (resp.status != 200) {
                throw new Error(`Unexpected status ${resp.status} getting ${url}`);
            }
            const response = await resp.json();
            this.setState({
                response,
            });
            if (onLoad) {
                setTimeout(() => onLoad(response), 0);
            }
        }
        catch (ex) {
            if (onError) {
                setTimeout(() => onError(ex), 0);
            }
            throw ex;
        }
    }
}
function resolve(baseUrl, relativeUrl) {
    const base = document.createElement('a');
    base.href = baseUrl + '/';
    return new URL(relativeUrl, base.href);
}
