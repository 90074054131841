import format from 'date-fns/format';
import * as React from 'react';
import { parseDateISOString, present } from '../util';
import { joinJsx } from '../util/joinJsx';
import { GalleryHitLayout } from './gallery-hit-layout';
/**
 * A React Component which renders a Media system message result as a card.
 */
export default class GalleryBlockHit extends React.Component {
    static DefaultLayout = GalleryHitLayout;
    render() {
        const { loading, hit, href, children, ...remainingProps } = this.props;
        const Layout = this.props.Layout || GalleryBlockHit.DefaultLayout;
        if (loading) {
            return React.createElement(Layout, { ...remainingProps, className: 'message-card loading widget-media-gallery__hit-block' },
                React.createElement("h5", { className: "card-title" }, "\u00A0"),
                React.createElement("span", { className: "media-caption" }, "\u00A0"),
                children);
        }
        const message = hit;
        const { title, slug, date, speakers, } = message;
        const id = 'objectID' in message ? message.objectID : message.id;
        const hasVideo = message.embeds && message.embeds.video &&
            message.embeds.video.available !== false;
        const hasAudio = message.embeds && message.embeds.audio &&
            message.embeds.audio.available !== false;
        const thumb = thumbnailUrl(message);
        const classes = [
            'message-card',
            hasVideo && 'video',
            hasAudio && 'audio',
            !thumb && 'no-image',
            // this goes last so that the Layout can attach `-thumb` to it
            'widget-media-gallery__hit-block',
        ].filter(present);
        return React.createElement(Layout, { ...remainingProps, className: classes.join(' '), href: href || `/message/${id}-${slug}`, thumbnailUrl: thumb },
            title && React.createElement("h5", { className: "card-title" }, title),
            React.createElement("span", { className: "media-caption" }, joinJsx([
                speakers && speakers.length > 0 && speakers[0].name,
                date && this.formatDate(parseDateISOString(date))
            ].filter(present), React.createElement(React.Fragment, null, "\u00A0\u2022\u00A0"))),
            children);
    }
    formatDate(date) {
        return format(date, DEFAULT_MSG_DATE_FORMAT);
    }
}
const DEFAULT_MSG_DATE_FORMAT = 'MMMM d, yyyy';
function thumbnailUrl(doc) {
    if (!doc.images) {
        return null;
    }
    if ('objectID' in doc) {
        // an Algolia hit
        return doc.images.wide_image_url || doc.images.banner_image_url || doc.images.square_image_url || null;
    }
    return doc.images.wide?.url ||
        doc.images.banner?.url ||
        doc.images.square?.url;
}
